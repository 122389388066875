import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCzLXGmrPeJZZg6a4CC4XWSo9uy963ZmVM",
  authDomain: "wedding-30797.firebaseapp.com",
  projectId: "wedding-30797",
  storageBucket: "wedding-30797.appspot.com",
  messagingSenderId: "924238043784",
  appId: "1:924238043784:web:22d15fb0b25fa508d69d3d",
  databaseURL: "https://wedding-30797-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);

export { storage, database };
