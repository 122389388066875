import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

export function Dialog({ open, onOpenChange, children }) {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </DialogPrimitive.Root>
  );
}

export function DialogContent({ children, className }) {
  return (
    <DialogPrimitive.Content className={`fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-0 m-0 overflow-hidden ${className}`}>
      <div className="relative bg-white rounded-lg overflow-hidden w-full h-full">
        {children}
      </div>
    </DialogPrimitive.Content>
  );
}

export function DialogClose({ children, className }) {
  return (
    <DialogPrimitive.Close className={`absolute top-2 right-2 opacity-100 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 ${className}`}>
      <X className="h-6 w-6" />
      <span className="sr-only">Close</span>
    </DialogPrimitive.Close>
  );
}
