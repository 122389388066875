import React from 'react';

export function Button({ children, onClick, className, disabled }) {
  return (
    <button
      onClick={onClick}
      className={`bg-blue-500 text-white py-2 px-4 rounded-md disabled:opacity-50 ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
