import React from 'react';
import { AlertTriangle } from 'lucide-react';

export function Alert({ variant, children }) {
  const colorClass = variant === 'destructive' ? 'text-red-700 bg-red-100' : 'text-blue-700 bg-blue-100';
  return (
    <div className={`p-4 mb-4 text-sm ${colorClass} rounded-lg flex items-center`}>
      <AlertTriangle className="h-4 w-4 inline mr-2" />
      {children}
    </div>
  );
}

export function AlertDescription({ children }) {
  return <span>{children}</span>;
}
