import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ref as dbRef, onValue, push, query, orderByChild } from "firebase/database";
import { storage, database } from './firebaseConfig';
import { Camera, Upload, AlertTriangle, X } from 'lucide-react';
import { Alert, AlertDescription } from './components/ui/alert';
import { Input } from './components/ui/input';
import { Button } from './components/ui/button';
import { Dialog, DialogContent, DialogClose } from './components/ui/dialog';
import './index.css';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library

const App = () => {
  const [gallery, setGallery] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);

  const MAX_VIDEO_SIZE = 500 * 1024 * 1024; // 500MB in bytes
  const MAX_PHOTO_SIZE = 15 * 1024 * 1024; // 15MB in bytes
  const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];
  const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/quicktime', 'video/x-msvideo', 'video/x-matroska'];

  useEffect(() => {
    const fetchGallery = () => {
      const galleryRef = query(dbRef(database, 'gallery'), orderByChild('timestamp'));
      onValue(galleryRef, (snapshot) => {
        const data = snapshot.val();
        const galleryArray = data ? Object.values(data).sort((a, b) => b.timestamp - a.timestamp) : [];
        setGallery(galleryArray);
      }, (error) => {
        console.error("Database error: ", error);
      });
    };

    fetchGallery();
    const intervalId = setInterval(fetchGallery, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const validateFile = (file) => {
    if (file.type.startsWith('image/')) {
      if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
        return `Format de imagine nevalid pentru "${file.name}". Vă rugăm să folosiți JPEG, PNG, HEIC sau HEIF.`;
      }
      if (file.size > MAX_PHOTO_SIZE) {
        return `Imaginea "${file.name}" depășește limita de 15MB.`;
      }
    } else if (file.type.startsWith('video/')) {
      if (!ALLOWED_VIDEO_TYPES.includes(file.type)) {
        return `Format de video nevalid pentru "${file.name}". Vă rugăm să folosiți MP4, MOV, AVI sau MKV.`;
      }
      if (file.size > MAX_VIDEO_SIZE) {
        return `Video-ul "${file.name}" depășește limita de 500MB.`;
      }
    } else {
      return `Tip de fișier nevalid pentru "${file.name}". Vă rugăm să încărcați o imagine sau un video.`;
    }
    return null;
  };

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setError('');
  };

  const handleUpload = () => {
    if (!name.trim()) {
      setError('Vă rugăm să introduceți numele dvs. înainte de a încărca.');
      return;
    }

    if (selectedFiles.length === 0) {
      setError('Vă rugăm să selectați cel puțin un fișier pentru a încărca.');
      return;
    }

    for (const file of selectedFiles) {
      const validationError = validateFile(file);
      if (validationError) {
        setError(validationError);
        return;
      }
    }

    setError('');
    setUploading(true);

    selectedFiles.forEach(file => {
      const uniqueFilename = `${uuidv4()}-${file.name}`; // Generate a unique filename
      const storageRef = ref(storage, `uploads/${uniqueFilename}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          console.error("Eroare la încărcare: ", error);
          setUploading(false);
          setError('Eroare la încărcarea fișierelor.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newGalleryItem = {
              url: downloadURL,
              timestamp: Date.now(),
              type: file.type.startsWith('image') ? 'image' : 'video',
              uploader: name.trim(),
            };
            push(dbRef(database, 'gallery'), newGalleryItem);
            setUploading(false);
            setSelectedFiles([]);
          }).catch((error) => {
            console.error("Eroare la obținerea URL-ului de descărcare: ", error);
            setUploading(false);
            setError('Eroare la încărcarea fișierelor.');
          });
        }
      );
    });
  };

  const loadMore = () => {
    setLoadingMore(true);
    setVisibleCount(prevCount => prevCount + 10);
    setLoadingMore(false);
  };

  return (
    <div className="max-w-md mx-auto p-4 space-y-8">
      <h1 className="text-3xl font-bold text-center">Sidonia & Bogdan 2024</h1>
      
      {/* Upload Section */}
      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">Împărtășește Momentele Tale</h2>
        <Input
          type="text"
          placeholder="Numele Tău"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full"
        />
        <div className="flex flex-col items-center justify-center w-full">
          <label htmlFor="file-upload" className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Upload className="w-10 h-10 mb-3 text-gray-400" />
              <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Apasă aici pentru a selecta</span></p>
              <p className="text-xs text-gray-500">Imagine (max 15MB) sau Video (max 500MB)</p>
            </div>
            <input id="file-upload" type="file" className="hidden" onChange={handleFileSelection} accept="image/*,video/*" multiple />
          </label>
          {selectedFiles.length > 0 && (
            <p className="mt-2 text-sm text-gray-600">{selectedFiles.length} fișier(e) selectate</p>
          )}
          <Button 
            onClick={handleUpload} 
            className="mt-4 w-full" 
            disabled={uploading || selectedFiles.length === 0}
          >
            {uploading ? 'Încărcare...' : 'Încarcă'}
          </Button>
        </div>
        <Alert>
          <AlertDescription>Imaginile nu pot fi șterse odată ce au fost încărcate și vor fi arătate tuturor invitaților. Vă rugăm să fiți adecvați.</AlertDescription>
        </Alert>
        {uploading && (
          <Alert>
            <Camera className="h-4 w-4" />
            <AlertDescription>Încărcăm momentele tale...</AlertDescription>
          </Alert>
        )}
        {error && (
          <Alert variant="destructive">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </div>
      
      {/* Gallery Section */}
      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">Galerie Live</h2>
        <div className="grid grid-cols-2 gap-4">
          {gallery.slice(0, visibleCount).map((item) => (
            <div key={item.id} className="relative cursor-pointer" onClick={() => setSelectedMedia(item)}>
              {item.type === 'image' ? (
                <img src={item.url} alt="Galerie item" className="w-full h-60 object-cover rounded-lg" />
              ) : (
                <video src={item.url} className="w-full h-60 object-cover rounded-lg" controls />
              )}
              <span className="absolute bottom-2 right-2 text-xs bg-black bg-opacity-50 text-white px-2 py-1 rounded">
                {new Date(item.timestamp).toLocaleTimeString()}
              </span>
              <span className="absolute top-2 left-2 text-xs bg-black bg-opacity-50 text-white px-2 py-1 rounded">
                {item.uploader}
              </span>
            </div>
          ))}
        </div>
        {visibleCount < gallery.length && (
          <button onClick={loadMore} className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded-md">
            {loadingMore ? 'Se încarcă...' : 'Încarcă mai multe'}
          </button>
        )}
      </div>

      {/* Modal for enlarged media */}
      {selectedMedia && (
        <Dialog open={!!selectedMedia} onOpenChange={() => setSelectedMedia(null)}>
          <DialogContent className="p-0 overflow-hidden">
            <DialogClose className="absolute right-4 top-4 rounded-sm opacity-100 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
              <X className="h-4 w-4" />
              <span className="sr-only">Închide</span>
            </DialogClose>
            <div className="flex flex-col items-center justify-center h-full w-full">
              {selectedMedia?.type === 'image' ? (
                <img src={selectedMedia.url} alt="Vedere mărită" className="max-w-full max-h-full object-contain" />
              ) : (
                <video src={selectedMedia?.url} controls className="max-w-full max-h-full object-contain" />
              )}
              <div className="mt-4 text-center">
                <p className="font-semibold">{selectedMedia?.uploader}</p>
                <p className="text-sm text-gray-500">
                  {selectedMedia && new Date(selectedMedia.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default App;
